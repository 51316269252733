<template>
    <div>
        <Navbar/>
        <b-container class="mt-5">
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <h3 class="txt_secondary text-uppercase mb-3">lista de catálogos</h3>
                </b-col>
            </b-row>

            <p class="txt_secondary text-uppercase mt-1"><strong>Diagnósticos y operaciones</strong></p>
            <hr>
            <b-row>
                <b-col class="mb-3" cols="12" sm="12" md="4" lg="4">
                    <b-button variant="bg_primary" class="bg_primary btn_options border-0 btn-block text-uppercase" :to="{ name:'registroCat', params:{id:'diagnosticos'}}">Diagnósticos</b-button>
                </b-col>
                <b-col class="mb-3" cols="12" sm="12" md="4" lg="4">
                    <b-button variant="bg_primary" class="bg_primary btn_options border-0 btn-block text-uppercase" :to="{ name:'registroCat', params:{id:'operaciones'}}">Operaciones</b-button>
                </b-col>
            </b-row>

            <p class="txt_secondary text-uppercase mt-1"><strong>Medicamentos</strong></p>
            <hr>
            <b-row >
                <b-col class="mb-3" cols="12" sm="12" md="4" lg="4">
                    <b-button variant="bg_primary" class="bg_primary btn_options border-0 btn-block text-uppercase" :to="{ name:'registroCat', params:{id:'medicamentos'}}">Medicamentos</b-button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"

export default {
    name: 'Main',
    components: { Navbar },

    methods: {
        
    }
}
</script>

<style scoped>

</style>